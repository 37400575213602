@import '../variables';

.loading-bar {
    width: 100%;
    height: 3px;
    position: relative;
    overflow: hidden;
    display: block;

    &__graphic {
        position: absolute;
        height: 3px;
        width: 5%;
        opacity: 0;
        background-color: $brand-colour;

        transition: opacity 400ms ease-in-out;

        &.inc {
            animation: loading-bar-animation-inc 1.2s linear infinite;
        }
        &.dec {
            animation: loading-bar-animation-dec 1.2s -0.6s linear infinite;
        }

        &.show {
            opacity: 1;
        }
    }


}

@keyframes loading-bar-animation-inc {
    from { left: -5%; width: 5%; }
    to { left: 130%; width: 80%;}
}

@keyframes loading-bar-animation-dec {
    from { left: -80%; width: 80%; }
    to { left: 110%; width: 10%;}
}