@import '../variables';
@import '../mixins';

.source {
    width: 100%;
    display: block;
    margin-bottom: 18px;

    &__name,
    &__occupied {
        @include pill-box();

        text-transform: capitalize;
        margin: 6px;

        &:before {
            display: inline-block;
            @include bold();
        }
    }

    &__events {
        display: inline;
        margin-left: 12px;
    }

    & .is--highlighted-fire {
        background: $colour-fire !important;
    }

    & .is--active {
        background: $colour-success;

        &:before {
            content: '☑';
            margin-right: 5px;
        }
    }
    & .is--inactive {
        background: $colour-alert;

        &:before {
            content: '☐';
            margin-right: 5px;
        }
    }

    & .is--occupied {
        background: $colour-active;

        &:before {
            content: '☑';
        }
    }
    & .is--unoccupied {
        background: $colour-inactive;
        color: #eee;

        &:before {
            content: '☐';
        }
    }
    & .is--Unknown {
        background: #ccc;
        color: #222;

        &:before {
            content: '?';
        }
    }
}

@media screen and (max-width: 480px) {
    .source {
        &__events {
            display: inline-block;
            margin: 0;
        }
    }
}
.dark-theme {
    .source {

        &__name,
        &__occupied {
            text-shadow: 0 0 4px #333;
        }
    }
}