.source-info {
    th, td {
        padding: 0.6rem 0.75rem;
    }

    &__lte-module-info {
        font-size: smaller;

        &>* {
            display: block;
            white-space: nowrap;
        }
    }
}