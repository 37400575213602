@mixin pill-box {
    border-radius: 0.4rem;
    line-height: 38px;
    vertical-align: top;
    padding: 0 12px;
    margin-right: 12px;
    display: inline-block;
    font-weight: 400;
    transition: background-color 0.2s ease-in-out;

    &.is--highlighted-warning {
        animation: pulse-shadow-warning 1.5s infinite ease-out;
        font-weight: 700;
    }
    &.is--highlighted-info {
        animation: pulse-shadow-info 1.5s infinite ease-out;
        font-weight: 700;
    }
    &.is--highlighted-success {
        animation: pulse-shadow-success 1.5s infinite ease-out;
        font-weight: 700;
    }
    &.is--highlighted-alert {
        animation: pulse-shadow-alert 1.5s infinite ease-out;
        font-weight: 700;
    }
    &.is--highlighted-fire {
        animation: pulse-shadow-fire 1.5s infinite ease-out;
        font-weight: 700;
    }

    &.is--alert {
        background: $colour-alert;
    }

    &.is--warning {
        background: $colour-warning;
    }

    &.is--info {
        background: $colour-info;
        color: $brand-white;
    }
}

@mixin bold {
    text-shadow:0px 0px 1px black;
}

@mixin modal-base() {
    &__buttons {
        width: 100%;
        text-align: right;

        &>button {
            margin-left: 8px;
            margin-bottom: 0;
        }
    }

    &:focus {
        outline: none;
    }
}
