@import '../variables';

$colour-reconnecting: #fd7;

.connection-indicator {
    display: inline-block;
    height: 32px;
    padding: 6px 12px;
    line-height: 20px;
    border-radius: 0.4rem;
    background: #ddd;
    color: #222;
    vertical-align: text-bottom;
    text-transform: capitalize;
    font-weight: 700;
    margin: 0 12px;

    transition: background-color 0.4s ease-in-out;

    &.is--connecting {
        background-color: $colour-active;
        animation: connecting-pulse 1s infinite ease-in-out;
    }

    &.is--connected {
        background-color: $colour-success;
    }

    &.is--closed {
        background-color: #ddd;
        cursor: pointer;
    }

    &.is--error {
        background-color: $colour-alert;
    }

    &.is--reconnecting {
        background-color: $colour-reconnecting;
        animation: reconnecting-pulse 1s infinite ease-in-out;
    }

    &__text {
        vertical-align: top;
        margin-left: 5px;
    }

    &__icon {
        margin-top: 2px;
        display: inline-block;
    }
}

@keyframes connecting-pulse {
    0% { background-color: $colour-active; }
    50% { background-color: lighten($colour-active, 5%); }
    100% { background-color: $colour-active; }
}
@keyframes reconnecting-pulse {
    0% { background-color: $colour-reconnecting; }
    50% { background-color: darken($colour-reconnecting, 15%); }
    100% { background-color: $colour-reconnecting; }
}

@media screen and (max-width: 480px) {
    .connection-indicator {
        width: 32px;
        padding: 7px 9px;

        &__text {
            display: none;
        }
    }
}