.admin-list-view {
    table {
        td, th {
            padding: 0.6rem 0.75rem;
        }

        button {
            margin-bottom: 0;
        }
    }
}