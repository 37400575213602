@import '../../variables';

.webgl-chart {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-shadow: 0px 0.25rem 0.75rem rgba(20, 20, 20, 0.2);
    padding: 1rem;
    margin: 1rem 0;

    transition: box-shadow 0.2s;

    * {
        user-select: none;
    }

    &__header {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
    }

    &__body {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        position: relative;
    }

    &__footer {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        margin-top: 0.5rem;
        height: 4.8rem;

        &.has--no-label {
            height: 2rem;
        }
    }

    &__canvas-holder {
        flex: 1 1 auto;
        position: relative;
        height: 32rem;
        overflow: hidden;
    }

    &.is--fft &__canvas-holder,
    &.is--rms &__canvas-holder,
    &.is--preview &__canvas-holder {
        height: 12rem;
    }

    &.is--preview {
        display: none;
    }

    &__canvas {
        width: 100%;
        height: 100%;
    }

    &__title {
        flex: 1 1 auto;
    }

    &__mini-title {
        flex: 1 1 auto;
        font-size: 90%;
    }

    &__buttons {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    &__body-buttons {
        display: flex;
        position: absolute;
        align-items: center;
        flex-direction: row;
        top: 0;
        right: 0;
        z-index: 1;
    }

    &__bottom-axis {
        flex: 1;
        border-top: 1px solid $brand-black;
        position: relative;
        overflow: hidden;
        font-size: 80%;

        transition: border 0.2s;

        &-ticks {
            height: 2.5rem;
        }

        &-units {
            text-align: right;
        }

        &-tick {
            position: absolute;
            display: flex;
            top: -1px;

            .text {
                transform: translate(-50%, 0.5rem);
            }

            .tick {
                position: absolute;
                left: 0;
                background: $brand-black;
                height: 0.4rem;
                width: 1px;
                display: inline-block;

                transition: background 0.2s;
            }
        }

        &-tick:first-child {
            .text {
                transform: translate(0, 0.5rem);
            }
        }

        &-tick:last-child {
            .text {
                transform: translate(-100%, 0.5rem);
            }
        }
    }

    &__left-axis {
        border-right: 1px solid $brand-black;
        position: relative;
        font-size: 80%;

        transition: border 0.2s;

        &-tick {
            position: absolute;
            display: flex;
            right: -1px;
            align-items: center;
            transform: translateY(-50%);

            .tick {
                margin-left: 0.3rem;
                background: $brand-black;
                height: 1px;
                width: 0.4rem;
                display: inline-block;

                transition: background 0.2s;
            }
        }

        &-label {
            position: absolute;
            transform: translateX(-50%) rotateZ(-90deg);
            top: calc(50% - 1.4rem);
            left: 1rem;
            text-align: center;
        }

        &-lines {
            position: absolute;
            right: 0;
            height: 100%;
            transform: translateX(100%);
            z-index: 1;
            pointer-events: none;
        }

        &-line {
            display: block;
            position: absolute;
            width: 100%;
            height: 0px;
            border-top: 1px dashed rgba(127, 127, 127, 0.8);

            &.is--near-zero {
                border-top-style: dotted;
            }
        }
    }

    &__left-axis-bumper {
        width: 9rem;
        margin-right: 0.5rem;
    }

    &__selection {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid $brand-black;
        position: absolute;
        z-index: 1;

        transition: border 0.2s;

        pointer-events: none;

        &.is--reselectable {
            pointer-events: unset;
            cursor: move;

            &:hover {
                background-color: rgba(127, 127, 127, 0.2);
            }
        }

        &-handle {
            position: absolute;
            display: block;

            transition: background ease-in-out 0.2s;

            &:hover {
                background-color: rgba(127, 127, 127, 0.2);
            }

            &.is--top-left {
                width: 1rem;
                height: 1rem;
                left: 0;
                top: 0;
                cursor: nwse-resize;
            }
            &.is--top {
                height: 1rem;
                top: 0;
                left: 1rem;
                right: 1rem;
                cursor: ns-resize;
            }
            &.is--top-right {
                width: 1rem;
                height: 1rem;
                right: 0;
                top: 0;
                cursor: nesw-resize;
            }
            &.is--left {
                width: 1rem;
                top: 1rem;
                bottom: 1rem;
                left: 0;
                cursor: ew-resize;
            }
            &.is--right {
                width: 1rem;
                top: 1rem;
                bottom: 1rem;
                right: 0;
                cursor: ew-resize;
            }
            &.is--bottom-left {
                width: 1rem;
                height: 1rem;
                left: 0;
                bottom: 0;
                cursor: nesw-resize;
            }
            &.is--bottom {
                height: 1rem;
                bottom: 0;
                left: 1rem;
                right: 1rem;
                cursor: ns-resize;
            }
            &.is--bottom-right {
                width: 1rem;
                height: 1rem;
                right: 0;
                bottom: 0;
                cursor: nwse-resize;
            }
        }
    }

    &__mouse-chart-points {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        top: 0;
        left: 0;

        &>div {
            width: 7px;
            height: 7px;
            border: 1px solid $brand-black;
            display: block;
            position: absolute;
            transform: translate(-4px, -4px);
            box-shadow: 0 0 0.5rem rgba(20, 20, 20, 0.5);
        }
    }

    &__bottom-legends {
        display: flex;
        justify-content: center;
        min-height: 5rem;
        font: caption;
    }

    &__bottom-legend {
        display: inline-block;
        padding: 1rem 1.5rem;
        min-width: 10em;

        &-colour {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid black;
            background-color: grey;
        }

        &-prefix {
            min-width: 3.1em;
            text-align: right;
            display: inline-block;
            .is--fft & {
                min-width: 6.8em;
            }
        }

        &-value {
            padding-left: 0.5em;
            min-width: 6em;
            display: inline-block;
            text-align: right;
            font-family: "Lucida Console", Monaco, monospace;
            font-size: 95%;
        // text-align: left;
        }
    }
}

.webgl-chart-tooltip {
    &__colour {
        width: 14px;
        height: 14px;
        display: inline-block;
        margin-right: 4px;
    }

    &__token {
        font-weight: bold;
    }
}

.dark-theme {
    .webgl-chart {
        box-shadow: 0px 0.25rem 0.75rem rgba(250, 250, 250, 0.2);

        &__selection {
            border-color: $brand-white;
        }

        &__left-axis {
            border-right-color: $brand-white;

            &-tick {
                .tick {
                    background: $brand-white;
                }
            }
        }

        &__bottom-axis {
            border-top-color: $brand-white;

            &-tick {

                .tick {
                    background: $brand-white;
                }
            }
        }

        &__mouse-chart-points {
            &>div {
                border-color: $brand-white;
                box-shadow: 0 0 0.5rem rgba(255, 255, 255, 0.5);
            }
        }

        &__selection {
            border-color: $brand-white;
        }
    }
}

@media screen and (max-width: 800px) {
    .webgl-chart {
        &__left-axis-bumper {
            width: 4rem;
            margin-right: 0.3rem;
        }

        &__title {
            font-size: 2.5rem;
        }
        &__header>&__left-axis-bumper {
            display: none;
        }

        &__canvas-holder {
            height: 26rem;
        }

        &.is--rms &__canvas-holder,
        &.is--preview &__canvas-holder {
            height: 10rem;
        }
    }
}