@import '../variables';
@import '../mixins';

.source-event {
    @include pill-box();
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    margin: 6px;
    width: 180px;
    max-width: 180px;
    background: #ddd;
    cursor: pointer;

    &.full-width {
        max-width: unset;
    }
}

.dark-theme {
    .source-event {
        text-shadow: 0 0 4px #333;
    }
}