@import '../mixins';

.source-detailed-info-modal {
    @include modal-base();

    &__controls {
        display: flex;
        flex-direction: row;
    }

    &__date {
        flex-grow: 1;
    }

    &__control-buttons {
        display: flex;
        justify-content: flex-end;
        margin-left: 1.5rem;
    }

    &__search {
        align-self: flex-end;
        margin-bottom: 1.5rem;
    }
}