.tag-value {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.tag-input {
    &__select {
        display: inline-block;
        width: unset;
        vertical-align: top;
        margin-right: 0.25rem;
    }
}