@import '../../../styles/milligram/_Color.sass';
@import '../../variables';

.source-data-type-selectors {
    padding: 10px 40px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
        line-height: 3.2rem;
    }

    button {
        background-color: transparent;
        color: $brand-black;
        font-size: 1.5rem;

        * {
            text-transform: none;
        }

        &.button-active {
            background-color: $color-primary;
            border-color: $color-primary;
            color: $brand-white;

            &:focus,
            &:hover {
                background-color: $color-secondary;
                border-color: $color-secondary;
            }
        }

        &:focus,
        &:hover {
            background-color: rgba(0.5, 0.5, 0.5, 0.3);
            border-color: rgba(0.5, 0.5, 0.5, 0.3);
        }
    }

    &__auto-y-zoom {
        position: absolute;
        right: 6px;
        display: none;

        label {
            display: inline-block;
            line-height: 3.2rem;
            margin: 0 0.5rem;
            cursor: pointer;
        }
    }
}

.source-data-type-selector {
    label {
        display: inline-block;
        line-height: 3.8rem;
        margin: 0 0.5rem;
        cursor: pointer;
    }

    &__colour {
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid $brand-black;
        margin-right: 0.5rem;
        border-radius: 0.25rem;
    }
}

.dark-theme {
    .source-data-type-selectors {
        button {
            color: $brand-white;
        }
    }
}

@media screen and (max-width: 480px) {
    .source-data-type-selectors {
        padding: 6px;
    }
}