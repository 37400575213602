@import '../variables';
@import '../mixins';

.source-event-selector-modal {
    @include modal-base();
    max-width: 760px;

    &__row {
        display: flex;
        align-items: center;

        .source-event {
            flex: 1;
        }
    }

    .is--alert {
        border-color: $colour-alert;

        & h1:before {
            content: 'Alert';
        }
    }

    .is--warning {
        border-color: $colour-warning;

        & h1:before {
            content: 'Warning';
        }
    }

    .is--info {
        border-color: $colour-info;

        & h1:before {
            content: 'Info';
        }
    }
}


@media screen and (max-width: 480px) {
    .source-event-selector-modal {
        &__row {
            align-items: unset;
            flex-direction: column;

            .source-event {
                flex: 1;
            }
        }
    }
}