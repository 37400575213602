$brand-colour: rgb(9, 119, 186);
$brand-white: #eee;
$brand-black: #111;

$colour-alert: #ff3232;
$colour-warning: #eeb71f;
$colour-success: #7c8;
$colour-active: #8af;
$colour-inactive: #234;
$colour-fire: $colour-alert;
$colour-info: $brand-colour;

$grey-colour: #999;
$grey-colour-lighter: lighten($grey-colour, 10%);
$grey-colour-darker: darken($grey-colour, 10%);