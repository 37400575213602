@import './variables';

.modal-base {
    background: white;
    margin: 40px auto;
    border-radius: 10px;
    border: 3px solid #ccc;
    padding: 1rem;
    max-width: calc(100% - 80px);
    max-height: calc(100% - 80px);
    overflow: auto;
    transition: border 0.2s ease-in-out;

    &-overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 10;
        background-color: rgba($brand-white, 0.75);
    }
}

.dark-theme {
    .modal-base {
        background: black;
        border-color: #666;

        &-overlay {
            background-color: rgba($brand-black, 0.75);
        }
    }
}