@import '../variables';
@import '../mixins';

.create-event-modal {
    @include modal-base();
    max-width: 460px;

    &.is--alert {
        border-color: $colour-alert;
    }

    &.is--warning {
        border-color: $colour-warning;
    }

    &.is--info {
        border-color: $colour-info;
    }
}