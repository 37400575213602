.image-zoom {
    overflow: hidden;
    border: 1px solid black;
    position: relative;
    width: 100%;

    img {
        position: absolute;
        height: 500px;
    }

    &__layer {
        height: 500px;
        background-image: url('../../img/imageLayerBackground.png');
        background-repeat: repeat;
    }
}

.dark-theme {
    .image-zoom {
        &__layer {
            background-image: url('../../img/imageLayerBackgroundDark.png');
        }
    }
}