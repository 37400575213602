@import '../variables';

.loading-spinner {
    width: 24px;
    height: 24px;

    &.center {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -12px;
    }

    &__graphic {
        width: 24px;
        height: 24px;
        opacity: 0;
        display: inline-block;

        border-top: 2px solid $brand-colour;
        border-bottom: 2px solid $brand-colour;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        border-radius: 50%;

        animation: spinner-animation 1200ms ease-in-out infinite;

        transition: opacity 400ms ease-in-out;

        &.show {
            opacity: 1;
        }

        &.small {
            width: 14px;
            height: 14px;
        }

        &.black {
            border-top-color: black;
            border-bottom-color: black;
        }
    }
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0);
    }
    100%
    {
        transform: rotate(720deg);
    }
}