@import './variables';

button {
    .icon {
        transform: translateY(0.2rem);
    }
}

.icon {
    height: 1.3rem;
    width: 1.3rem;
    display: inline-block;

    transition: fill 0.2s;

    svg {
        width: 1.3rem;
        height: 1.3rem;
    }
}

.dark-theme .icon,
.dark-theme.icon
{
    fill: $brand-white;
}

.light-theme .icon,
.light-theme.icon {
    fill: $brand-black;
}