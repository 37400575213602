@import '../variables';

.header {
    background-color: $brand-colour;
    width: 100%;
    height: 60px;
    display: block;
    padding: 6px;

    &__title {
        color: $brand-white;
        display: inline-block;
        margin: 0;
        line-height: 100%;
        font-size: 32pt;
    }

    &__logo {
        float: right;
        width: 52px;
        height: 100%;
        margin: 4px;
    }

    &__startup-errors {
        line-height: 20px;
        vertical-align: text-bottom;
        margin: auto;
        height: 32px;

        border-color: $colour-fire;
        color: $colour-fire !important;
        background: $brand-white !important;

        &:hover {
            color: darken($color: $colour-fire, $amount: 20%) !important;
            border-color: darken($color: $colour-fire, $amount: 20%) !important;
            background-color: darken($color: $brand-white, $amount: 10%) !important;
        }
    }

    &__user {
        position: absolute;
        right: 70px;
        text-align: right;
        margin-bottom: 0;
        margin-top: 4px;
    }
}

@media screen and (max-width: 480px) {
    .header {
        &__title {
            font-size: 1.8rem;
            width: 80px;
           margin-top: 4px;
        }
    }
}