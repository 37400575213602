@import '../../variables';

.react-hint {
    padding: 5px;
    position: absolute;
    z-index: 9999;
    cursor: default;
    animation: .5s fadeIn;
    pointer-events: none;

    &__content {
        padding: 10px;
        border-radius: 5px;
        background: $brand-black;
        color: $brand-white;

        &.is--large {
            min-width: 700px;
        }
    }

    &:after {
        content: '';
        width: 0;
        height: 0;
        margin: auto;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 5px solid transparent;
    }

    &.hide:after {
        display: none;
    }

    &--top:after {
        top: auto;
        border-bottom: none;
        border-top-color: $brand-black;
    }

    &--left:after {
        left: auto;
        border-right: none;
        border-left-color: $brand-black;
    }

    &--right:after {
        right: auto;
        border-left: none;
        border-right-color: $brand-black;
    }

    &--bottom:after {
        bottom: auto;
        border-top: none;
        border-bottom-color: $brand-black;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.dark-theme {
    .react-hint {
        &__content {
            background: $brand-white;
            color: $brand-black;
        }

        &--top:after {
            border-top-color: $brand-white;
        }
        &--left:after {
            border-left-color: $brand-white;
        }
        &--right:after {
            border-right-color: $brand-white;
        }
        &--bottom:after {
            border-bottom-color: $brand-white;
        }
    }
}