@import 'variables';
@import 'mixins';

body {
    background-color: $brand-white;
    margin: 0;
    overflow: scroll;

    transition: background-color 0.2s, color 0.2s;

    select,
    textarea,
    input {
        transition: color 0.2s;
    }

    select option {
        color: $brand-black;
    }

    &.dark-theme {
        background-color: $brand-black;
        color: $brand-white;

        select,
        textarea,
        input {
            color: $brand-white;
        }

        select option {
            color: $brand-black;
        }

        pre {
            background-color: lighten($brand-black, 15%);
        }

        li.react-tabs__tab {
            &--selected {
                background: $brand-black;
                color: $brand-white;
                text-shadow: 0px 0px 1px white;
            }

            &:focus:after {
                background: $brand-black;
            }
        }
    }
}

.app {
    main {
        margin: 10px;
    }
}

li.react-tabs__tab {
    margin: 0;

    &--selected {
        background: $brand-white;

        transition: background 0.2s, color 0.2s;
        @include bold();
    }

    &:focus:after {
        background: $brand-white;

        transition: background 0.2s;
    }
}

pre {
    padding-left: 4px;
    background-color: #eee;
}

@keyframes pulse-shadow-warning {
    0% {
        box-shadow: 0 0 0 0 $colour-warning;
    }
    100% {
        box-shadow: 0 0 0 7px fade-out($colour-warning, 1);
    }
}
@keyframes pulse-shadow-info {
    0% {
        box-shadow: 0 0 0 0 $colour-info;
    }
    100% {
        box-shadow: 0 0 0 7px fade-out($colour-info, 1);
    }
}
@keyframes pulse-shadow-success {
    0% {
        box-shadow: 0 0 0 0 $colour-success;
    }
    100% {
        box-shadow: 0 0 0 7px fade-out($colour-success, 1);
    }
}
@keyframes pulse-shadow-alert {
    0% {
        box-shadow: 0 0 0 0 $colour-alert;
    }
    100% {
        box-shadow: 0 0 0 7px fade-out($colour-alert, 1);
    }
}
@keyframes pulse-shadow-fire {
    0% {
        box-shadow: 0 0 0 0 $colour-fire;
    }
    100% {
        box-shadow: 0 0 0 7px fade-out($colour-fire, 1);
    }
}


@media screen and (max-width: 480px) {
    .react-tabs__tab-list {
        display: block;
        overflow-y: auto;
        width: 100%;
        white-space: nowrap;
    }
}
