@import '../../variables';

.timeline {
    position: relative;
    width: 100%;
    height: 200px;
    margin-bottom: 12px;
    cursor: move;

    &__data-container {
        position: relative;
        width: calc(100% - 100px);
        left: 100px;
        height: calc(100% - 52px);
        overflow: hidden;
        border: 1px solid $grey-colour;
        border-top: none;
    }

    &__time-headers {
        position: relative;
        height: 32px;
        border-bottom: 1px solid $grey-colour-lighter;

        &.is--upper .timeline__time-header:not(:first-child) {
            border-left: 1px solid $grey-colour-lighter;
        }

    }

    &__time-header {
        position: absolute;
        padding-top: 2px;
        padding-left: 6px;
        padding-bottom: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 3;
        height: 100%;

        &.is--clickable {
            cursor: pointer;

            &:hover {
                background: $grey-colour-lighter;
            }
        }

        &-bar {
            position: absolute;
            display: inline-block;
            top: 0;
            bottom: 0;
            width: 1px;
            border-left: 1px solid $grey-colour-lighter;

            &.is--dashed {
                border-left-style: dashed;
            }
        }
    }

    &__groups {
        position: absolute;
        margin-top: -4px;
        background: $brand-white;
        width: 100px;
        transition: background 0.2s;
        top: 45px;
        padding-top: 70px;
        padding-right: 5px;
        text-align: right;
    }

    &__events {
        position: relative;
        margin-top: 4px;
    }
    &__event {
        position: absolute;
        display: inline-block;
        background-color: $brand-colour;
        transition: background-color 0.2s;
        height: 20px;
        z-index: 1;
        cursor: pointer;

        &:hover {
            background-color: lighten($brand-colour, 15%);
        }

        &-start {
            position: absolute;
            display: inline-block;
            width: 1px;
            height: 24px;
            top: -2px;
            background: $brand-black;
            pointer-events: none;

            transition: background-color 0.2s;
        }

        &:hover &-start {
            background: lighten($brand-colour, 30%);
            height: 26px;
            top: -3px;
            width: 2px;
        }

        &-select-box {
            display: inline-block;
            position: absolute;
            cursor: pointer;
            background: rgba(127, 127, 127, 0.1);

            &:hover {
                background: rgba(127, 127, 127, 0.2);
            }
        }
    }

    &__selection {
        position: absolute;
        display: inline-block;
        top: 64px;
        pointer-events: none;
        background: rgba(lighten($brand-colour, 35%), 0.7);
        z-index: 2;
    }

    &__now {
        position: absolute;
        width: 1px;
        height: 100%;
        top: 32px;
        background-color: midnightblue;
    }

    &__source-events {
        display: block;
        height: 25px;
        position: relative;
    }

    &__button-row {
        display: flex;
    }

    &__time-buttons {
        margin-left: 4px;
    }

    &__zoom-slider {
        flex: 1;
        margin-left: 8px;
        display: inline-flex;

        & strong {
            align-self: center;
        }

        & input {
            margin: 0 6px;
            flex: 1;
        }
    }
}

@media screen and (max-width: 480px) {
    .timeline {
        margin-bottom: 62px;

        &__button-row {
            flex-direction: column;
        }

        &__time-buttons {
            display: none;
        }
    }
}

.timeline-source-event {
    display: inline-block;
    position: absolute;

    width: 10px;
    margin-top: 2px;
    margin-left: -5px;
    height: 20px;
    background: grey;
    z-index: 3;
    cursor: pointer;

    &.is--alert {
        background: $colour-alert;

        &:hover {
            background: lighten($colour-alert, 10%);
        }
    }

    &.is--warning {
        background: $colour-warning;

        &:hover {
            background: lighten($colour-warning, 10%);
        }
    }

    &.is--info {
        background: $colour-info;

        &:hover {
            background: lighten($colour-info, 10%);
        }
    }
}

.dark-theme {
    .timeline {
        &__time-headers {
            border-bottom-color: $grey-colour-darker;
        }

        &__time-header {
            &.is--clickable {
                &:hover {
                    background: $grey-colour-darker;
                }
            }
        }

        &__event {
            &-start {
                background: $brand-white;
            }
        }

        &__groups {
            background: $brand-black;
        }

        &__now {
            background-color: lightblue;
        }
    }
}