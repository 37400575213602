@import '../variables';

.groups-view {
    display: inline-block;
    position: relative;

    &__select {
        display: inline-block;
        height: 32px;
        margin-bottom: 0;
        vertical-align: text-bottom;
        z-index: 1;

        color: $brand-black !important;

        background-color: $brand-white;

        &:active {
            border-color: $grey-colour;
        }
    }
}