@import '../../styles/milligram/_Color.sass';

.slider-checkbox {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 3.8rem;

  &.mini {
    height: 3rem;
    width: 5rem;
  }

  &.mini &__thumb {
    &:before {
      left: 1rem;
      bottom: 0.8rem;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + &__thumb {
    background-color: $color-primary;
  }

  input:checked + &__thumb:before {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }

  &__thumb {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.2s ease-in-out;
    border-radius: 0.4rem;

    &:before {
      position: absolute;
      content: "";
      height: 1.6rem;
      width: 1.6rem;
      left: 1.2rem;
      bottom: 1.2rem;
      background-color: white;
      transition: 0.2s ease-in-out;
      border-radius: 0.2rem;
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
    }
  }
}
