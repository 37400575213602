.graphs-tab {
    &__inputs {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    &__inputs-right {
        display: flex;
    }

    &__input {
        display: flex;
        margin-left: 2rem;
        align-items: center;
        font-weight: normal;

        select {
            margin-bottom: 0;
            margin-left: 0.2rem;
        }
    }

    &__input span {
        padding-right: 0.6rem;
        padding-left: 0.6rem;
    }

    &__input-value {
        width: 32px;
        display: inline-block;
    }

    &__show-extra-buttons {
        display: none;
        margin-left: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .graphs-tab {
        &__inputs {
            align-items: center;
            flex-direction: column;
        }

        &__show-extra-buttons {
            display: inline-block;
        }

        &__inputs-right {
            flex-direction: column;

            &.close {
                display: none;
            }
        }

        &__input {
            justify-content: flex-end;
            margin-left: 0;

            &>:first-child {
                margin-right: auto;
            }
        }
    }
}