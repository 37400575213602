@import '../variables';
@import '../mixins';

.source-event-modal {
    @include modal-base();
    max-width: 460px;

    h1:before {
        content: 'Unknown';
    }

    &.is--alert {
        border-color: $colour-alert;

        & h1:before {
            content: 'Alert';
        }
    }

    &.is--warning {
        border-color: $colour-warning;

        & h1:before {
            content: 'Warning';
        }
    }

    &.is--info {
        border-color: $colour-info;

        & h1:before {
            content: 'Info';
        }
    }

    &__text {
        white-space: pre-line;
    }
}